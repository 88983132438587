/* eslint-disable @typescript-eslint/no-explicit-any */
import { post, get, deleteRequest, patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ParamsPatchZoneActionPoste, ParamsPostZoneActionPoste } from './types'

const getPosteZone = createAsyncThunk(
  'get/posteZone',
  async (_, thunkApi) => {
    try {
      const response = await get('/dexcarto-demo/zone-action-poste/')
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postPosteZone = createAsyncThunk(
  'post/posteZone',
  async (params: ParamsPostZoneActionPoste, thunkApi) => {
    try {
      const response = await post('/dexcarto-demo/zone-action-poste/', params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deletePosteZone = createAsyncThunk(
  'delete/posteZone',
  async (zoneId: string, thunkApi) => {
    try {
      const response = await deleteRequest(`/dexcarto-demo/zone-action-poste/${zoneId}/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchPosteZone = createAsyncThunk(
  'patch/posteZone',
  async (params: ParamsPatchZoneActionPoste, thunkApi) => {
    try {
      const response = await patch(`/dexcarto-demo/zone-action-poste/${params.id}/`, params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export { getPosteZone, postPosteZone, deletePosteZone, patchPosteZone }
