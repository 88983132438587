import { useEffect, Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { get } from '@osrdata/app_core/dist/requests'
import { BugModal, HelpModal, SuggestionModal } from 'components/Modal'
import { auth } from '@osrdata/app_core'
import { RootState } from 'store'
import { closeBugModal, closeHelpModal, closeSuggestionModal } from 'store/app'
import history from 'config/history'
import { terms } from 'assets'
import { Loader, TopBar } from 'components'
import Map from './Map'
import AccessDenied from './AccessDenied'

import './index.scss'
import Perimeter from './Perimeter'
import CollectionsView from './Collections'

const APP_NAME = 'DEX CARTO'
const ACCESS_PERM = 'dexcarto-demo::USER'

/**
 * App root
 *
 * This component manages :
 * - authent
 * - routing logic
 * - views
 */
export default function App() {
  const dispatch = useDispatch()
  const { isLogged, appPermissions, isLoading } = useSelector((state: RootState) => state.user)
  const { bugModal, suggestionModal, helpModal } = useSelector((state: RootState) => state.app)
  const [accessDenied, setAccessDenied] = useState(false)
  const [helpContent, setHelpContent] = useState('')

  useEffect(() => {
    if (!isLogged) {
      dispatch(auth.attemptLoginOnLaunch())
    }
  }, [isLogged])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
        history.push('/denied')
      } else {
        get('/dexcarto-demo/helper/').then(res => {
          setHelpContent(res.help_text)
        })
      }
    }
  }, [isLogged, appPermissions])

  return (
    <Suspense fallback={<Loader />}>
      <>
        <TopBar
          appName={APP_NAME}
          hasAccess={!isLoading && !accessDenied}
        />
        <main id="app">
          {(isLoading || !isLogged) ? <Loader message={terms.Common.loading} /> : (
            <Router history={history}>
              <Switch>
                <Route path="/denied" component={AccessDenied} />
                <Route path="/collections" component={CollectionsView} />
                <Route path="/perimeter/:id" component={Perimeter} />
                <Route path="/:slug" component={Map} />
                <Route path="/" component={Map} />
              </Switch>
              <BugModal
                openModal={bugModal}
                handleClose={() => dispatch(closeBugModal())}
              />
              <SuggestionModal
                openModal={suggestionModal}
                handleClose={() => dispatch(closeSuggestionModal())}
              />
              <HelpModal
                openModal={helpModal}
                handleClose={() => dispatch(closeHelpModal())}
                content={helpContent}
              />
            </Router>
          )}
        </main>
      </>
    </Suspense>
  )
}
