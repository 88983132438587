import { terms } from 'common/terms'
import { ReactElement, useState } from 'react'
import nextId from 'react-id-generator'
import { useDispatch, useSelector } from 'react-redux'
import { MAIN_SOURCES, SOURCES_IDS } from 'components/Layers/common'
import { FlagOutlined, Star, StarBorder, WarningAmber } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { RootState } from 'store'
import { deleteUserFavoriteObject, postUserFavoriteObject } from 'store/userFavorite/userFavorite.thunk'
import moment from 'moment'
import { GeoJSONLayerDataSignal } from 'components/Layers/GeoJSON/GeoJSONLayer'
import ObjectCommentModal from 'components/Modal/ObjectCommentModal/ObjectCommentModal'
import { UserFavoriteObjectCommentType } from 'store/userFavorite/types'
import { get } from '@osrdata/app_core/dist/requests'
import { useHistory } from 'react-router-dom'
import { FeatureProperties, Field } from './types'

interface Props {
  title: Field
  featureProperties: FeatureProperties
  featureId: string
  source: string
  handleClickPoste: (geom: GeoJSON.Geometry, zapId: string, isModifiable: boolean) => () => void
  handleHoverPoste: (zapId: string) => () => void
  handleClickZap: (zapId: string) => () => void
  handleHoverZap: (zapId: string) => () => void
  handleClickBal: (balId: string) => () => void
  handleHoverBal: (balId: string) => () => void
}

const CustomPopup = ({
  title, featureProperties, featureId, source, handleClickPoste, handleClickZap, handleClickBal, handleHoverPoste,
  handleHoverBal, handleHoverZap,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { favoriteObjects, objectsComments } = useSelector((state: RootState) => state.userFavorite)
  const [commentModal, setCommentModal] = useState(false)

  let objectSource = source
  if (objectSource === SOURCES_IDS.actionZoneLabel) objectSource = SOURCES_IDS.actionZone
  if (objectSource === SOURCES_IDS.ihmZoneLabel) objectSource = SOURCES_IDS.ihmZone
  const layer = Object.entries(MAIN_SOURCES).find(([, value]) => value === objectSource)?.[0]
  const isFavorite = layer
   && favoriteObjects.some(object => object.object_id === featureId && object.layer_slug === layer)
  const comments = objectsComments.filter(comment => comment.object_id === featureId && comment.layer_slug === layer)

  const handleClickFavorite = () => {
    if (!featureId || !layer) return
    if (isFavorite) {
      const favoriteObject = favoriteObjects
        .find(object => object.object_id === featureId && object.layer_slug === layer)
      if (favoriteObject) {
        dispatch(deleteUserFavoriteObject(favoriteObject.id))
      }
    } else {
      dispatch(postUserFavoriteObject({
        object_id: featureId,
        layer_slug: layer,
        layer_id_field: 'id',
      }))
    }
  }

  const handleMouseEnterGeometry = (geometry: GeoJSON.Geometry, isOld: boolean) => () => {
    GeoJSONLayerDataSignal.value = {
      type: 'FeatureCollection',
      features: [{ type: 'Feature', geometry, properties: { old: isOld } }],
    }
  }

  const handleMouseLeaveGeometry = () => {
    GeoJSONLayerDataSignal.value = { type: 'FeatureCollection', features: [] }
  }

  return (
    <>
      <div className="custom-popup-title">
        <strong>{`${title.name} : ${title.value}`}</strong>
        <div className="actions">
          <Tooltip title={terms.featuresPopup.flag} placement="top">
            <button type="button" className="favorite" onClick={() => setCommentModal(true)}>
              <FlagOutlined />
            </button>
          </Tooltip>
          {featureId && (
          <Tooltip
            title={isFavorite ? terms.featuresPopup.removeFavorite : terms.featuresPopup.addFavorite}
            placement="top"
          >
            <button type="button" className="favorite" onClick={handleClickFavorite}>
              {isFavorite ? <Star /> : <StarBorder />}
            </button>
          </Tooltip>
          )}
        </div>
      </div>
      <div className="custom-popup-container">
        {featureProperties.fields.map((fields: Field[]) => (
          <div className="field-group" key={nextId()}>
            {fields.map((field: Field) => (
              <div className="field" key={nextId()}>
                <strong>{`${field.name} : `}</strong>
                <span>{field.value}</span>
              </div>
            ))}
          </div>
        ))}
        {featureProperties.showPostes && (
        <div className="field-group">
          {featureProperties.postes.length === 0 ? (
            <div className="field">
              <strong>{`${terms.featuresPopup.properties.postes} : `}</strong>
              <span>{terms.featuresPopup.properties.noData}</span>
            </div>
          ) : (
            <div className="field">
              <strong>{`${terms.featuresPopup.properties.postes} : `}</strong>
              {featureProperties.postes.map(poste => (
                <button
                  type="button"
                  key={nextId()}
                  onClick={handleClickPoste(poste.zapGeometry, poste.zapId, poste.isModifiable)}
                  onMouseEnter={handleHoverPoste(poste.zapId)}
                >
                  <span>{poste.posteName}</span>
                </button>
              ))}
            </div>
          )}
        </div>
        )}
        {(featureProperties.neighbouringZones.zone_action_poste.length > 0
        || featureProperties.neighbouringZones.zone_bal.length > 0) && (
          <div className="field-group">
            <div className="field">
              <strong>{`${terms.featuresPopup.neighbouringZones} : `}</strong>
              {featureProperties.neighbouringZones.zone_action_poste.map(zone => (
                <button
                  type="button"
                  key={nextId()}
                  onClick={handleClickZap(zone.id)}
                  onMouseEnter={handleHoverZap(zone.id)}
                >
                  <span>{zone.libelle}</span>
                </button>
              ))}
              {featureProperties.neighbouringZones.zone_bal.map(zone => (
                <button
                  type="button"
                  key={nextId()}
                  onClick={handleClickBal(zone.id)}
                  onMouseEnter={handleHoverBal(zone.id)}
                >
                  <span>{zone.libelle}</span>
                </button>
              ))}
            </div>
          </div>
        )}
        <div className="field-group">
          {Object.entries(featureProperties.collections).map(([reference, subfamily]) => (
            <div className="field" key={nextId()}>
              <strong>{`${terms.featuresPopup.collections.reference} `}</strong>
              <Tooltip title={reference} placement="top">
                <button
                  type="button"
                  onClick={() => {
                    get('/dexcarto-demo/ouranos-element/', { search: reference }).then(response => {
                      const { results } = response
                      if (results.length > 0) {
                        history.push(`/collections?details=${results[0].id}`)
                      }
                    })
                  }}
                >
                  <span>{`${subfamily.ligne_ids.join('-')}-${reference}`}</span>
                </button>
              </Tooltip>
            </div>
          ))}
          {Object.entries(featureProperties.collections).length === 0 && (
            <div className="field">
              <strong>{`${terms.featuresPopup.collections.reference} `}</strong>
              <span>{terms.featuresPopup.properties.noData}</span>
            </div>
          )}

        </div>
        {Object.values(featureProperties.rcData).length > 0 && (
          <>
            <div className="field-group">
              <div className="field">
                <strong>Statuts RC (données IDF, si disponible) :</strong>
              </div>
              <div className="field">
                <span>
                  {`${
                    featureProperties.rcData.nombre_etude_en_cours
                  } ${
                    terms.featuresPopup.properties.rcData.nombre_etude_en_cours
                  }`}
                </span>
              </div>
              <div className="field">
                <span>
                  {`${
                    featureProperties.rcData.nombre_en_attente_rc
                  } ${
                    terms.featuresPopup.properties.rcData.nombre_en_attente_rc
                  }`}
                </span>
              </div>
              <div className="field">
                <span>
                  {`${
                    (featureProperties.rcData.nombre_dc_non_commence || 0)
                  + (featureProperties.rcData.nombre_dc_bloque || 0)
                  + (featureProperties.rcData.nombre_dc_non_termine || 0)
                  } ${terms.featuresPopup.properties.rcData.rcReceived}`}
                </span>
              </div>
              <div className="field">
                <span>
                  {`- ${
                    featureProperties.rcData.nombre_dc_non_commence
                  } ${
                    terms.featuresPopup.properties.rcData.nombre_dc_non_commence
                  }`}
                </span>
              </div>
              <div className="field">
                <span>
                  {`- ${
                    featureProperties.rcData.nombre_dc_bloque
                  } ${
                    terms.featuresPopup.properties.rcData.nombre_dc_bloque
                  }`}
                </span>
              </div>
              <div className="field">
                <span>
                  {`- ${
                    featureProperties.rcData.nombre_dc_non_termine
                  } ${
                    terms.featuresPopup.properties.rcData.nombre_dc_non_termine
                  }`}
                </span>
              </div>
            </div>
            {Object.keys(featureProperties.collections).map(reference => (
              <div className="field-group" key={nextId()}>
                <div className="field">
                  <strong>Indices et fiches : </strong>
                  <button
                    type="button"
                    onClick={() => {
                      get('/dexcarto-demo/ouranos-element/', { search: reference }).then(response => {
                        const { results } = response
                        if (results.length > 0) {
                          history.push(`/collections?details=${results[0].id}`)
                        }
                      })
                    }}
                  >
                    <span>Voir fiche collection</span>
                  </button>
                </div>
              </div>
            ))}
          </>
        )}
        {featureProperties.history.map(details => (
          <div className="field-group" key={nextId()}>
            <div className="field">
              <strong>{`${terms.featuresPopup.modification}: `}</strong>
              <span>
                {moment(details.modification_date).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
            {details.modified_fields.map(field => (
              <>
                <div className="field" key={nextId()}>
                  <strong>{`${terms.featuresPopup.modifiedField} : `}</strong>
                  <span>{field.field}</span>
                </div>
                {!field.isGeom ? (
                  <>
                    <div className="field">
                      <span>{`${terms.featuresPopup.oldValue} : `}</span>
                      <span>{field.old_value}</span>
                    </div>
                    <div className="field">
                      <span>{`${terms.featuresPopup.newValue} : `}</span>
                      <span>{field.new_value}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="field">
                      <span>{`${terms.featuresPopup.oldValue} : `}</span>
                      <button
                        type="button"
                        onMouseEnter={handleMouseEnterGeometry(JSON.parse(field.old_value.replaceAll("'", '"')), true)}
                        onMouseLeave={handleMouseLeaveGeometry}
                      >
                        <span>{terms.featuresPopup.geometry}</span>
                      </button>
                    </div>
                    <div className="field">
                      <span>{`${terms.featuresPopup.newValue} : `}</span>
                      <button
                        type="button"
                        onMouseEnter={handleMouseEnterGeometry(JSON.parse(field.new_value.replaceAll("'", '"')), false)}
                        onMouseLeave={handleMouseLeaveGeometry}
                      >
                        <span>{terms.featuresPopup.geometry}</span>
                      </button>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        ))}
      </div>
      <div className="custom-popup-comments">
        {comments.map(comment => (
          <div
            key={comment.id}
            className={comment.type === UserFavoriteObjectCommentType.Commentaire ? 'comment' : 'flag'}
          >
            <span>
              {`${comment.type}: ${comment.content}`}
            </span>
            <Tooltip title={comment.content} placement="right">
              {comment.type === UserFavoriteObjectCommentType.Commentaire ? (
                <WarningAmber />
              ) : (<FlagOutlined />)}
            </Tooltip>
          </div>
        ))}
      </div>

      {layer && (
        <ObjectCommentModal
          onModal={commentModal}
          handleClose={() => setCommentModal(false)}
          objectId={featureId}
          objectLibelle={
            featureProperties.fields.flatMap(field => field)
              .find(field => field.name === 'Libellé')?.value || ''
          }
          layerIdField="id"
          layerSlug={layer}
        />
      )}

    </>
  )
}

export default CustomPopup
